import { IconButton, Switch, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: "1px 3px",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  svg: {
    "font-size": "19px",
    
  },
  a:{
    "color":"#707070",
  }
}));

export const accountStatusOptions = [
  { item: "All", value: "" },
  { item: "Unauthorized", value: "UNAUTHORIZED" },
  { item: "Authorized", value: "AUTHORIZED" },
];

export const jobStatusOptions = [
  { item: "All", value: "" },
  { item: "Active", value: "Active" },
  { item: "Not Active", value: "Not Active" },
];

export const headings = [
  { name: "Name", width: 50 },
  { name: "Email", width: 50 },
  { name: "Account Status", width: 50 },
  // { name: "Job Status", width: 50 },
  { name: "Actions", width: 50 },
];
