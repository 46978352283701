export const walletHeading = [
    { name: "ID" },
    { name: "FULLNAME" },
    { name: "TOTAL REDEEM AMOUNT" },
    { name: "ACTION", width: 50 },
];
export const walletDetailsHeading = [
    { name: "ID" },
    { name: "FULLNAME" },
    { name: "WALLET BALANCE" },
    { name: "REDEEM AMOUNT" },
    { name: "DATE" },
]