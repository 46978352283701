export const pendingFinanceHeading = [
    { name: "ID" },
    { name: "IN/OUT" },
    { name: "CATOGORIES" },
    { name: "AMOUNT" },
    { name: "REMARKS" },
    { name: "DATE" },
    { name: "ACTION" },
    { name: "APPROVAL BY DEVJITH" },
    { name: "APPROVAL BY ASHIK" },
];