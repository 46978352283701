export const walletHeading = [
    { name: "TOTAL SALES" },
    { name: "TOTAL PAYMENT" },
    { name: "TOTAL PROFIT" },
    { name: "TOTAL EXTRA" },
    { name: "TOTAL DEDUCTIONS" },
    { name: "TOTAL RELEASED PAYMENT" },
    { name: "PENDING AMOUNT TO RELEASED" },
    { name: "TOTAL SUBMITTED AMOUNT (INVOICE)" },
    { name: "TOTAL RECIEVED AMOUNT (INVOICE)" },
    { name: "TOTAL SUBMITTED AMOUNT (GST)" },
    { name: "TOTAL RECIEVED AMOUNT (GST)" },
    { name: "TOTAL MONEY IN" },
    { name: "TOTAL MONEY OUT" },
];
