import React from "react";
import StatusPanel from "./StatusPanel";

export const RightPanel = ({slotDate}) => {
  return (
    <div className="RightPanel">
      <StatusPanel slotDate = {slotDate} />
    </div>
  );
};
