export const walletHeading = [
    { name: "ID" },
    { name: "FULLNAME" },
    { name: "TOTAL PAYMENT" },
    { name: "ACTION", width: 50 },
];
export const walletDetailsHeading = [
    { name: "ID" },
    { name: "FULLNAME" },
    { name: "HOTEL" },
    { name: "OUTLET" },
    { name: "AMOUNT" },
    { name: "WALLET BALANCE" },
]